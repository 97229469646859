@import '../src/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Gelasio:ital,wght@0,400;0,700;1,400;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');


$font-headers: 'Gelasio',
serif;
$font-text: 'Open Sans',
sans-serif;

p,
a,
td,
span,
li {
    font-family: $font-text;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-headers;
    color: $eclipse;
}

.top-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 10;
    background: rgba(247, 219, 187, 0.9);

    img {
        max-width: 200px;
        padding: 20px;
    }

    .navbar-nav {
        text-align: center;

        a {
            margin-left: 10px;
            margin-right: 10px;
            text-align: center;
            font-size: 20px;
            color: $eclipse;
            text-decoration: none;
            transition: color 0.2s 0.2s;

            &:hover {
                color: $mule-fawn;
            }
        }
    }

}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
        flex-direction: row-reverse;
        margin-top: 30px;
    }
}

.hero-wrapper {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    position: relative;

    .row {
        margin-left: 0;
        margin-right: 0;

        div[class*='col-'] {
            padding-left: 0;
            padding-right: 0;

            .hero-text {
                position: absolute;
                left: 50%;
                top: 35%;
                transform: translate(-50%, -50%);
                z-index: 1;

                h1 {
                    color: white;
                    font-size: 60px;
                    text-shadow: 1px 1px 8px black;
                    text-align: center;
                }

                p {
                    color: white;
                    font-size: 20px;
                    text-shadow: 1px 1px 8px black;
                    text-align: center;
                    font-style: italic;
                }
            }
        }
    }
}

.hero-image-wrapper {
    height: 600px;
    background: linear-gradient(to bottom, rgba(247, 219, 187, 0), rgba(147, 80, 42, 1));
    opacity: 0.5;
}

.hero-image {
    height: 600px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.home-wrapper {
    .hero-image-wrapper {
        height: 100vh;
    }

    .hero-image {
        height: 100vh;
    }
}



section {
    padding: 80px 0px;

    img {
        width: 100%;
        max-width: 400px;
    }

    h2 {
        font-size: 50px;
        text-align: center;
        padding-bottom: 30px;
    }
}

.colored-section {
    background: $mule-fawn;

    * {
        color: white;
    }
}

.no-padding-section {
    padding: 0 0;
}

.food-row {
    div[class*="col-"] {
        overflow: hidden;
        padding-left: 0;
        padding-right: 0;

        .food-overlay {
            background: linear-gradient(to bottom, rgba(247, 219, 187, 0), rgba(147, 80, 42, 1));
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }

        .food-bkg {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            min-height: 300px;
            transition: all .2s
        }

        .food-text-wrapper {
            position: absolute;
            bottom: 10px;
            left: 10px;
            z-index: 2;

            * {
                color: white;
                text-shadow: 1px 1px 4px black;
            }

            p {
                font-size: 0;
                transform: scale(0);
                transition: transform .2s;
            }

            a {
                text-decoration: none;
            }
        }

        &:hover {
            .food-bkg {
                transform: scale(1.2);
            }

            .food-text-wrapper {
                p {
                    font-size: 18px;
                    transform: scale(1);
                }
            }
        }
    }
}

.map-responsive {
    overflow: hidden;
    padding-bottom: 540px;
    position: relative;
    height: 0;
}

.map-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

footer {
    padding: 40px 0;
    background: $eclipse;

    * {
        color: white;
    }

    a {
        color: white !important;
    }
}

.main-menu-item {
    display: inline-block;
    font-family: $font-headers;
    font-size: 20px;
    border-left: 5px solid;
    padding: 15px 20px;
    border-color: $raw-sienna;

    &:last-child {
        border-right: 5px solid;
        border-color: $raw-sienna;
    }

    &:hover {
        background: $givry !important;
        cursor: pointer;
    }
}

.main-menu-active-item {
    background: $givry !important;
    cursor: pointer;
}

#sub-categories {
    text-align: center;
    width: 85%;
    margin: 0 auto;
    display: block;

    .subCat-item {
        display: inline-block;
        background-color: $raw-sienna;
        color: white;
        padding: 10px 20px;
        margin-bottom: 3px;
        margin-left: 2px;
        margin-right: 2px;
        border: 0;
        outline: none;

        &:hover,
        &:active,
        &:focus {
            cursor: pointer;
            background: $mule-fawn !important;
        }

        &:empty {
            display: none;
        }
    }
}

#dishes {
    margin-top: 40px;

    .dish-item {
        margin-bottom: 10px;

        .item-wrapper {
            vertical-align: top;
            border-left: 5px solid $givry;
            border-right: 5px solid $givry;
            margin-bottom: 10px;
            height: 100%;
            padding: 10px;

            .dish-name {
                font-weight: bold;
                font-size: 20px;
                font-family: $font-headers;
                text-align: center;
            }

            .dish-details {
                font-size: 12px;
                font-style: italic;
                text-align: center;
                white-space: pre-line;
            }

            .dish-price {
                text-align: center;
                font-weight: bold;
            }
        }
    }
}

.logo-bottom {
    width: 100%;
    max-width: 150px;
}

.menu-wrapper {
    .container {
        text-align: center;
        padding: 100px 0px;
    }
}

@media (max-width:991px) {
    .navbar {
        justify-content: flex-end !important;
    }

    .navbar-nav {
        margin-top: 55px;
        text-align: left;
        margin-left: -36%;
    }
}

@media (max-width:768px) {
    .hero-wrapper .row div[class*='col-'] .hero-text {
        transform: translate(-50%, -25%);
    }
}

.navbar-toggler {
    margin-top: 20px;
}

.btn {
    font-family: $font-headers;
}

.btn-order {
    width: 265px;
    font-size: 25px !important;
    background: $mule-fawn !important;
    color: white !important;
    border-color: $raw-sienna !important;
    margin: 0 auto;
    display: block !important;
    padding: 10px 30px !important;
    box-shadow: 1px 1px 8px black;

    &:hover {
        background: $corvette !important;
        color: $mule-fawn !important;
    }
}